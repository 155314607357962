module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-XGP4576NPQ","head":true,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"wypozycz.online","author":"","short_name":"wypozycz","start_url":"/","background_color":"#0072ff","theme_color":"#0072ff","display":"minimal-ui","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a41194638df0445cee790702fdd57244"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
